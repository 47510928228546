<template>
  <div class="container">
    <h1>Murshisoft Bizness</h1>
    <p>
      Murshisoft Bizness is a comprehensive accounting-cum inventory software.
      It’s designed from top to bottom with a full insight into the modern
      business requirements. Any accountant who used the old ledger-based
      accounting will find the accounting system in this software as an extended
      ledger-based system.
    </p>

    <h2>Notable Features</h2>
    <p>
      Chart of Accounts, which is a hierarchical based list of accounts with
      parent-child accounts. The accountant can customize the chart of accounts
      as per the requirements of the company.
    </p>

    <h3>Accounting vouchers</h3>
    <p>
      Vouchers are categorized as Receipt Vouchers, Payment Vouchers, and Day
      Voucher. This categorization is just only for the purpose of printing
      purposes. As the software can print receipt/payment vouchers on a
      dot-matrix printer instead of manual receipt/payment vouchers. The
      vouchers are double-entry based as the accountant has to enter both debit
      and credit sides. For those who are not familiar with the double-entry
      based system, we provide also short vouchers such as Cash receipt from
      customers where the user just select the customer and the payment method
      cash or bank. The software will convert this to a double-entry based
      voucher automatically. All postings in vouchers are posted to the
      concerned accounts automatically.
    </p>
    <h3>Financial Years</h3>
    <p>
      The financial year is the base of an accounting period. First, you have to
      set up the financial year with starting and ending dates. Any transaction
      whether it is in accounts or inventory should be within the period of the
      financial year. The software always keeps an eye the data entry is done
      strictly within the period specified.
    </p>
    <h3>Costing Centers</h3>
    <p>
      Any operational account transaction i.e. income or expense can be
      connected to a costing center. This will eliminate the need for creating
      separate accounts for each and every costing center (activity) within a
      business. For example for a contracting company, there will be multiple
      projects running at the same time. Instead of creating separate accounts
      for the purchase of raw materials for each project, there will be one
      account for “Raw material purchase”. And add all the projects in the cost
      centers. During the voucher creation process, you enter the account “Raw
      material purchase” then select Project A as a costing center. Thus the
      software separates the raw material purchase for each project in a
      separate ledger without the need to create a sperate account for each
      project.
    </p>
  </div>
</template>

<script>
// import { defineComponent, onMounted } from "vue";
// import { FetchData } from "@/data/productdetail.js";
// export default defineComponent({
//   setup() {
//     const { data, error, isLoading, run: getData } = FetchData();
//     onMounted(async () => {
//       await getData();
//     });
//     return { data, error, isLoading, getData };
//   },
// });
</script>

<style lang="scss" scoped>
.container {
  h1 {
    font-size: 60px;
    font-weight: bolder;
    padding: 40px 0;
  }
  h2 {
    font-size: 55px;
    font-weight: bold;
    padding: 30px 0;
  }
  h3 {
    font-size: 40px;
    font-weight: initial;
    padding: 30px 0;
  }
  p {
    font-size: 30px;
    padding: 10px 0;
  }
}
</style>
